import {io} from "socket.io-client";
import App from "./services/App";
import Language from "./services/Language";
import QuestionAB from "./services/QuestionAB";
import QuestionEN from "./services/QuestionEN";
import User from "./services/User";

const production = true;
let socket;

if (production) {
  socket = io();
} else {
  socket = io("ws://localhost:4200");
}

const app = new App();
const language = new Language();
const questionAB = new QuestionAB();
const questionEN = new QuestionEN();
const user = new User();

export {socket, app, language, questionAB, questionEN, user};