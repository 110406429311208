<script>
  import {language} from "./shared/services";

  const languageStore = language.store;
  let dropdownRef;
  let isDropdownVisible = false;

  const onShowDropdown = () => {
    isDropdownVisible = !isDropdownVisible;
  };

  const setLanguage = (lang) => {
    $languageStore = lang;
    isDropdownVisible = false;
  };

  const closeDropdown = (event) => {
    if (!event.target.matches(".dropdown__btn")) {
      isDropdownVisible = false;

      // const dropdowns = document.getElementsByClassName("dropdown__content");

      // for (let i = 0; i < dropdowns.length; i += 1) {
      //   const openDropdown = dropdowns[i];

      //   if (openDropdown.classList.contains("show")) {
      //     isDropdownVisible = false;
      //   }
      // }
    }
  }
</script>

<svelte:window on:click={closeDropdown}/>

<style>
  .header {
    flex-shrink: 1;
    padding: var(--spacing-md);
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .header__logo, .header__lang {
    display: flex;
    align-items: center;
  }
  .logo {
    width: 48px;
    height: 48px;
  }

  .dropdown__btn {
    margin: 0;
    background-color: transparent;
    color: black;
    border-color: rgb(var(--red));
    transition: background-color 225ms ease-in-out, color 225ms ease-in-out;
  }
  .dropdown__btn:hover, .dropdown__btn:focus {
    background-color: rgb(var(--red));
    border-color: rgb(var(--white));
    color: white;
  }
  .dropdown__img {
    height: 16px;
    width: 16px;
    vertical-align: middle;
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .dropdown__content {
    position: absolute;
    top: 100%;
    right: 0;
    width: 160px;
    padding: var(--spacing-sm) 0;
    display: none;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .dropdown__content__link {
    /* color: black; */
    padding: var(--spacing-sm) var(--spacing-lg);
    cursor: pointer;
  }
  .dropdown__content__link:hover { background-color: #ddd; }
  .show { display:block; }
</style>

<header class="header">
  <div class="header__logo">
    <img class="logo" src="assets/images/logo.png" alt="Logo">
  </div>
  <div class="header__lang">
    <div class="dropdown">

      <button on:click={onShowDropdown} class="dropdown__btn">
        {#if $languageStore === "EN"}
          <img class="dropdown__img" src="assets/images/uk.svg" alt="EN"> English
        {:else if $languageStore === "AB"}
          <img class="dropdown__img" src="assets/images/ab.svg" alt="AB"> Arabic
        {/if}
      </button>

      <div class="dropdown__content" class:show={isDropdownVisible}>
        <div class="dropdown__content__link" on:click={() => setLanguage("EN")}>
          <img class="dropdown__img" src="assets/images/uk.svg" alt="EN"> English
        </div>
        <div class="dropdown__content__link" on:click={() => setLanguage("AB")}>
          <img class="dropdown__img" src="assets/images/ab.svg" alt="AB"> Arabic
        </div>
      </div>

    </div>
  </div>
</header>