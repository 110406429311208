<script>
  import Question from "./Question.svelte";
  import {
    socket,
    app,
    language,
    questionAB,
    questionEN,
    user
  } from "./shared/services";
  import moment from "moment";

  const appStore = app.store;
  const languageStore = language.store;
  const questionABStore = questionAB.store
  const questionENStore = questionEN.store;
  const userStore = user.store;
  const m = moment();

  let answerAllFirst = "";

  const finish = () => {
    let answerSum = 0;

    if ($languageStore === "AB") {
      $questionABStore.forEach((question) => {
        question.answers.forEach((answer) => {
          if (answer.isSelected) answerSum ++;
        });
      });
    } else {
      $questionENStore.forEach((question) => {
        question.answers.forEach((answer) => {
          if (answer.isSelected) answerSum ++;
        });
      });
    }

    if (answerSum < 11) {
      answerAllFirst = "Answer all questions before proceeding!";
      return;
    }

    let sum = 0;

    if ($languageStore === "AB") {
      sum = Object.keys($userStore.answersAB).reduce((acc, answer) => {
        return acc += $userStore.answersAB[answer];
      }, 0);
    } else {
      sum = Object.keys($userStore.answersEN).reduce((acc, answer) => {
        return acc += $userStore.answersEN[answer];
      }, 0);
    }

    $userStore.finalScore = (sum / 11).toFixed(2);
    $userStore.date = m.format("MMMM DD, YYYY");
    $userStore.time = m.format('h:mm:ss a');

    if ($userStore.finalScore <= 2.0) {
      $userStore.finalScoreTextAB = "المسائل الخاصة بالأفراد تؤثر على جوهر اداء المؤسسة. يتوجب ترتيب مسائل الأفراد من حيث الأولوية و التعامل مع المسائل ذات الأولوية العليا باسرع وقت ممكن";
      $userStore.finalScoreTextEN = "People issues impact core business performance, must prioritize and address the high priority issues ASAP";
    } else if ($userStore.finalScore > 2.0 && $userStore.finalScore <= 3.0) {
      $userStore.finalScoreTextAB = "النظم الأساسية متواجدة، لكن مسائل الأفراد تحد اداء المؤسسة بشكل جدي";
      $userStore.finalScoreTextEN = "Basic systems in place, but people issues seriously limit business performance";
    } else if ($userStore.finalScore > 3.0 && $userStore.finalScore <= 3.75) {
      $userStore.finalScoreTextAB = "بعض الممارسات الجيدة و لكن يوجد بعض الثغرات الملحوظة. يتوجب تحديد اين القيمة المفقودة القصوى و اتخاذ الاجرائات اللازمة";
      $userStore.finalScoreTextEN = "Some good people practices, but also some serious gaps. Need to identify where value is lost or unrealized and take action";
    } else if ($userStore.finalScore > 3.75 && $userStore.finalScore <= 4.5) {
      $userStore.finalScoreTextAB = "اداء متميز بشكل عام. بعض مبادرات التحسين في مجال الأفراد ستحقق قيمة اضافية";
      $userStore.finalScoreTextEN = "Doing very well in general. Some improvement initiatives can return additional value.";
    } else {
      $userStore.finalScoreTextAB = "مؤسستكم متميزة جدا في أمور الأفراد لدرجة تجعلنا نرغب في دراستكم او العمل معكم";
      $userStore.finalScoreTextEN = "You're a unicorn!! Can we study you and work for you?";
    }

    socket.emit("finish", {
      language: $languageStore,
      account: $userStore.account,
      answersAB: $userStore.answersAB,
      answersEN: $userStore.answersEN,
      finalScore: $userStore.finalScore,
      finalScoreTextAB: $userStore.finalScoreTextAB,
      finalScoreTextEN: $userStore.finalScoreTextEN,
      date: $userStore.date,
      time: $userStore.time
    });

    $userStore.isFinished = true;
  };
</script>

<style>
  .questions {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .divider {
    border-bottom: 1px solid #dedede;
  }
  .btn--wrapper {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .center {
    text-align: center;
    padding: var(--spacing-sm);
  }
  .qa {
    position: relative;
  }
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: white;
    z-index: 2;
  }
</style>

<div class="questions">
  <p class="center">
    <strong>
      {#if $languageStore === "AB"}
        برجاء قراءة الوصف في كل المربعات بدقة و اختيار المربع الأكثر مطابقة لأوضاع مؤسستكم في كل فئة من الفئات التالية
      {:else}
        Please read the descriptions in the boxes for each category and click on the box that best describes your organization
      {/if}
    </strong>
  </p>
  <div class="qa">
    {#if $appStore.isLoading}
      <div class="loading">
        <p>Loading, please wait...</p>
      </div>
    {/if}
    {#if $languageStore === "AB"}
      {#each $questionABStore as question, i}
        <Question {question} {i}/>
        <div class="divider"></div>
      {/each}
    {:else}
      {#each $questionENStore as question, i}
        <Question {question} {i}/>
        <div class="divider"></div>
      {/each}
    {/if}
  </div>
  <div class="btn--wrapper">
    <p>{answerAllFirst}</p>
    <button on:click={finish}>
      {#if $languageStore === "AB"}
        انهاء
      {:else}
        FINISH
      {/if}
    </button>
  </div>
</div>