<script>
  import {socket, language, user} from "./shared/services";

  const languageStore = language.store;
  const userStore = user.store;

  const form = {
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    position: "",
    industry: ""
  };

  const onSubmit = () => {
    $userStore.isLoggedIn = true;
    $userStore.account = form;

    socket.emit("getResult", {email: form.email});
    socket.emit("getQuestions");
  };
</script>

<style>
  .signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .signup__heading {
    padding: var(--spacing-sm);
    box-sizing: border-box;
    text-align: center;
  }
  .signup__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .signup__bg {
    margin: var(--spacing-md) 0;
    height: 192px;
  }
</style>
<div class="signup">
  <div class="signup__heading">
    <h3>
      {#if $languageStore === "AB"}
        تقييم مختصر للممارسات و النظم الخاصة بالأفراد
      {:else}
        High Level People Health Check 33
      {/if}
    </h3>
    <p>
      {#if $languageStore === "AB"}
        هذا استبيان سريع و مختصرللوقوف على مستوى اداء مؤسستكم على المحاور الجوهرية الخاصة بالأفراد. اجمالي الأختيارات يحدث<br>
        نتيجة رقمية تعكس فعالية ممارسات ونظم و عمليات الأفراد في مؤسستكم
      {:else}
        This is a quick, high level questionnaire that will give you an indication of how your organization is doing on critical People issues and give an overall People effectiveness score<br>
        You are asked to select the best fitting description of your organization along several critical people dimensions. You answers produce an overall People Score for your organization.
      {/if}
    </p>
  </div>

  <form class="signup__form" on:submit|preventDefault={onSubmit}>

    <input
      type="text"
      placeholder={$languageStore === "AB" ? "* الاسم" : "First name *"}
      required
      bind:value={form.firstName}>

    <input
      type="text"
      placeholder={$languageStore === "AB" ? "* اللقب" : "Last name *"}
      required
      bind:value={form.lastName}>

    <input
      type="email"
      placeholder={$languageStore === "AB" ? "* البريد الالكتروني" : "Email *"}
      required
      bind:value={form.email}>

    <input
      type="text"
      placeholder={$languageStore === "AB" ? "* المؤسسة" : "Company *"}
      required
      bind:value={form.company}>

    <input
      type="text"
      placeholder={$languageStore === "AB" ? "* المركزاو الوظيفة" : "Position *"}
      required
      bind:value={form.position}>

    <input
      type="text"
      placeholder={$languageStore === "AB" ? "* الصناعة او المجال" : "Industry"}
      bind:value={form.industry}>

    <button type="submit">
      {#if $languageStore === "AB"}
        ابدأ
      {:else}
        START
      {/if}
    </button>
  </form>

  <img class="signup__bg" src="assets/images/signup-bg.svg" alt="Background">
</div>