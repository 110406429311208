<script>
  import Answer from "./Answer.svelte";

  export let question;
  export let i;
</script>

<style>
  .question {
    position: relative;
  }

  .question__header {
    text-align: center;
  }

  .answers {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    padding: 0 1em 1em 0;
    box-sizing: border-box;
  }
  /* add right margin */
  .answers::after {
    content: '';
    border-left: 1em solid transparent;
  }

  /* Desktop */
  @media only screen and (min-width: 992px) {
    .answers {
      overflow-x: hidden;
    }
  }
</style>

<div class="question">

  <div class="question__header">
    <h3>{question.index} / 11 - {question.title}</h3>
  </div>

  <div class="answers">
    {#each question.answers as answer, j}
      <Answer {answer} {i} {j}/>
    {/each}
  </div>

</div>