import {writable} from "svelte/store";

const store = writable({
  isLoading: true
});

class App {
  get store() {
    return store;
  }
}

export default App;