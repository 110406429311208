<script>
  import {onDestroy, onMount} from "svelte";
  import {socket, app, language, user} from "./shared/services";

  const appStore = app.store;
  const languageStore = language.store;
  const userStore = user.store;
  let languageUnsub;
  let isMobile = false;

  const getChart = (answers) => {
    return {
      type: "radar",
      data: {
        labels: Object.keys(answers).map((answer) => answer),
        datasets: [{
          label: $languageStore === "AB" ? "نتائج بحثك" : "Your results",
          data: Object.keys(answers).map((answer) => answers[answer]),
          backgroundColor: "rgba(208, 33, 42, 0.2)",
          borderColor: "rgb(208, 33, 42)",
          borderWidth: 2
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scale: {
          ticks: {min: 1, max: 5, stepSize: 1},
          pointLabels: {
            fontColor: "#000000",
            fontFamily: `"Nunito", sans-serif`,
            fontSize: isMobile ? 8 : 12,
            lineHeight: 1
          }
        }
      }
    };
  };

  const retake = () => {
    $userStore.answersAB = {};
    $userStore.answersEN = {};
    $userStore.finalScore = 0;
    $userStore.finalScoreTextAB = "";
    $userStore.finalScoreTextEN = "";
    $userStore.isFinished = false;

    $appStore.isLoading = true;

    socket.emit("getQuestions");
  };

  onMount(() => {
    const contentEl = document.getElementById("content");
    const chartEl = document.getElementById("chart");
    const chartElCtx = chartEl.getContext("2d");

    contentEl.scrollTo({top: 0});

    if (contentEl.offsetWidth <= 992) {
      isMobile = true;
    }

    languageUnsub = language.store.subscribe((store) => {
      if ($languageStore === "AB") {
        new Chart(chartElCtx, getChart($userStore.answersAB));
      } else {
        new Chart(chartElCtx, getChart($userStore.answersEN));
      }
    });
  });

  onDestroy(() => {
    languageUnsub();
  })
</script>

<style>
  .result {
    padding: var(--spacing-md);
    box-sizing: border-box;
  }
  .score {
    display: flex;
    flex-direction: column;
  }

  .table {
    /* width: 100%; */
    border-spacing: 0;
    border-collapse: collapse;
  }
  .table__row:nth-child(even) {
    background-color: #dedede;
  }
  .table__data,
  .table__heading,
  .table__data-ab,
  .table__heading-ab {
    padding: var(--spacing-sm);
    border: 1px solid #bdbdbd;
  }
  .table__data-ab {
    text-align: right;
  }
  .table__heading,
  .table__heading-ab {
    color: white;
    background-color: rgb(var(--red));
    /* text-align: start; */
  }
  .chart {
    position: relative;
    width: 100%;
    height: 320px;
  }
  /* Desktop */
  @media only screen and (min-width: 992px) {
    .score {
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .score__table {
      width: 360px;
      margin-right: 1em;
    }
    .chart {
      height: 480px;
      width: 480px;
    }
    .scores {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .scores__table {
      width: calc(840px + 1em);
    }
  }
</style>

<div class="result">
  <div class="text-center">
    {#if $languageStore === "AB"}
      <h2>نتائج تقييمك</h2>
      <p>{$userStore.date}</p>
      <p>{$userStore.finalScoreTextAB}</p>
      <button on:click={retake}>اعادة الاستبيان</button>
    {:else}
      <h2>Your results</h2>
      <p>{$userStore.date}</p>
      <p>{$userStore.finalScoreTextEN}</p>
      <button on:click={retake}>RETAKE THE QUESTIONNAIRE</button>
    {/if}
  </div>

  <div class="divider"></div>

  <div class="score">
    <table class="table score__table">
      {#if $languageStore === "AB"}
        <tr class="table__row">
          <th class="table__heading-ab">النتيجة</th> <!--Score-->
          <th class="table__heading-ab">الفئة</th> <!--People dimension-->
        </tr>
        {#each Object.keys($userStore.answersAB) as answer}
          <tr class="table__row">
            <td class="table__data-ab">{$userStore.answersAB[answer]}</td>
            <td class="table__data-ab">{answer}</td>
          </tr>
        {/each}
        <tr class="table__row">
          <th class="table__heading-ab">{$userStore.finalScore}</th>
          <th class="table__heading-ab">النتيجة الاجمالية</th>
        </tr>
      {:else}
        <tr class="table__row">
          <th class="table__heading">People dimension</th>
          <th class="table__heading">Score</th>
        </tr>
        {#each Object.keys($userStore.answersEN) as answer}
          <tr class="table__row">
            <td class="table__data">{answer}</td>
            <td class="table__data">{$userStore.answersEN[answer]}</td>
          </tr>
        {/each}
        <tr class="table__row">
          <th class="table__heading">Final score</th>
          <th class="table__heading">{$userStore.finalScore}</th>
        </tr>
      {/if}
    </table>

    <div class="chart">
      <canvas id="chart"></canvas>
    </div>
  </div>

  <div class="divider"></div>

  <div class="scores">
    <table class="table scores__table">
      {#if $languageStore === "AB"}
        <tr class="table__row">
          <th class="table__heading">ترجمة</th> <!--Interpretation-->
          <th class="table__heading">النتيجة</th> <!--Score-->
          <th class="table__heading">يسجل مفتاح</th> <!--Score Key-->
        </tr>
        <tr class="table__row">
          <td class="table__data-ab">التحديات الخاصة بالأفراد تؤثر على أداء المؤسسة بشكل جوهري. يتوجب ترتيب هذه التحديات من حيث الأولوية و التعامل مع التحديات ذات الأولوية العليا بأسرع وقت ممكن</td>
          <td class="table__data-ab">2.0</td>
          <td class="table__data-ab">اقل من</td>
        </tr>
        <tr class="table__row">
          <td class="table__data-ab">النظم الأساسية متواجدة و لكن التحديات الخاصة بالأفراد تحد من اداء المؤسسة بشكل مؤثر</td>
          <td class="table__data-ab">2.0 & 3.0</td>
          <td class="table__data-ab">ما بين</td>
        </tr>
        <tr class="table__row">
          <td class="table__data-ab">بعض الممارسات الجيدة متواجدة و لكن يوجد عدة ثغرات مؤثرة. يتوجب تحديد اين القيمة المفقودة القصوى و اتخاذ الإجراءات اللازمة لسد الثغرات</td>
          <td class="table__data-ab">3.0 & 3.75</td>
          <td class="table__data-ab">ما بين</td>
        </tr>
        <tr class="table__row">
          <td class="table__data-ab">اداء متميزبشكل عام. بعض المبادرات الإضافية في مجال الأفراد ستحقق تحسن و قيمة اضافية ملحوظة لمؤسستكم</td>
          <td class="table__data-ab">3.75 & 4.5</td>
          <td class="table__data-ab">ما بين</td>
        </tr>
        <tr class="table__row">
          <td class="table__data-ab">مؤسستكم متميزة جدا في أمور الأفراد لدرجة تجعلنا نرغب في دراستكم او العمل معكم</td>
          <td class="table__data-ab">4.5</td>
          <td class="table__data-ab">اكثر من</td>
        </tr>
      {:else}
        <tr class="table__row">
          <th class="table__heading">Score key</th>
          <th class="table__heading">Score</th>
          <th class="table__heading">Interpretation</th>
        </tr>
        <tr class="table__row">
          <td class="table__data">Below</td>
          <td class="table__data">2.0</td>
          <td class="table__data">People issues impact core business performance, must prioritize and address high priority issues ASAP</td>
        </tr>
        <tr class="table__row">
          <td class="table__data">Between</td>
          <td class="table__data">2.0 & 3.0</td>
          <td class="table__data">Basic systems in place, but people issues seriously limit business performance</td>
        </tr>
        <tr class="table__row">
          <td class="table__data">Between</td>
          <td class="table__data">3.0 & 3.75</td>
          <td class="table__data">Some good people practices, but also some serious gaps. Need to identify where value is lost or unrealized and take action</td>
        </tr>
        <tr class="table__row">
          <td class="table__data">Between</td>
          <td class="table__data">3.75 & 4.5</td>
          <td class="table__data">Doing very well in general. Some improvement initiatives can return additional value</td>
        </tr>
        <tr class="table__row">
          <td class="table__data">Above</td>
          <td class="table__data">4.5</td>
          <td class="table__data">You're a unicorn!! Can we study you and work for you?</td>
        </tr>
      {/if}
    </table>
  </div>

</div>