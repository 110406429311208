import {writable} from "svelte/store";

const store = writable({
  isLoggedIn: false,
  isFinished: false,
  account: {},
  answersAB: {},
  answersEN: {},
  finalScore: 0,
  finalScoreTextAB: "",
  finalScoreTextEN: "",
  date: ""
});

class User {
  get store() {
    return store;
  }
}

export default User;