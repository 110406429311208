import {writable} from "svelte/store";

const store = writable([]);

class QuestionEN {
  get store() {
    return store;
  }
}

export default QuestionEN;