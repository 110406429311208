<script>
  import {language, questionAB, questionEN, user} from "./shared/services";

  export let answer;
  export let i;
  export let j;

  const languageStore = language.store;
  const questionABStore = questionAB.store;
  const questionENStore = questionEN.store;
  const userStore = user.store;

  const select = () => {
    $questionABStore[i].answers.forEach((answer) => answer.isSelected = false);
    $questionABStore[i].answers[j].isSelected = true;
    $questionENStore[i].answers.forEach((answer) => answer.isSelected = false);
    $questionENStore[i].answers[j].isSelected = true;

    $userStore.answersAB[$questionABStore[i].title] = answer.index;
    $userStore.answersEN[$questionENStore[i].title] = answer.index;
  };
</script>

<style>
  .answer {
    flex: 0 0 80%;
    margin: 1em 0 0 1em;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-bottom-right-radius: 32px;
    border-top-left-radius: 32px;
    background-color: #dedede;
    transition: background-color 225ms ease-in-out, box-shadow 225ms ease-in-out, color 225ms ease-in-out;
    scroll-snap-align: center;
  }

  .answer__list {
    padding: 1em;
    padding-left: 2em;
    flex-grow: 1;
    box-sizing: border-box;
  }
  .answer__list--ab {
    padding-left: 1em;
    padding-right: 2em;
    direction: rtl;
  }
  .answer__list__item {
    font-size: 14px;
    /* line-height: 1; */
    white-space: normal;
  }
  .answer__list__item--ab {
    padding-right: 1em;
    direction: rtl;
  }

  .answer__number {
    align-self: flex-end;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1em 1em 0;
    border-radius: 50%;
    color: white;
    background-color: rgb(var(--red));
    transition: background-color 225ms ease-in-out, color 225ms ease-in-out;
  }
  .answer__number__selected {
    background-color: white;
    color: rgb(var(--red));
  }

  .selected {
    background-color: rgb(var(--red));
    color: white;
  }

  /* Desktop */
  @media only screen and (min-width: 992px) {
    .answer {
      flex: 0 0 calc(20% - 1em);
      cursor: pointer;
    }
  }
</style>

<div class="answer {
  answer.isSelected ?
  "selected mat-elevation-z8" :
  "mat-elevation-z2"
}" on:click={select}>
  <ul class={
    $languageStore === "AB" ?
    "answer__list answer__list--ab" :
    "answer__list"
  }>
    {#each answer.list as listItem}
      <li class={
        $languageStore === "AB" ?
        "answer__list__item answer__list__item--ab" :
        "answer__list__item"
      }>{listItem}</li>
    {/each}
  </ul>
  <div class="{
    answer.isSelected ?
    "answer__number answer__number__selected" :
    "answer__number"
  }">{answer.index}</div>
</div>