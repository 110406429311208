<script>
  import {onMount} from "svelte";
  import Header from "./Header.svelte";
  import Questions from "./Questions.svelte";
  import Result from "./Result.svelte";
  import Signup from "./Signup.svelte";
  import {socket, app, questionAB, questionEN, user} from "./shared/services";

  const appStore = app.store;
  const questionABStore = questionAB.store;
  const questionENStore = questionEN.store;
  const userStore = user.store;

  onMount(() => {
    socket.on("getResultResponse", (params) => {
      const {
        answersAB,
        answersEN,
        finalScore,
        finalScoreTextAB,
        finalScoreTextEN,
        date
      } = params;

      $userStore.isLoggedIn = true;
      $userStore.isFinished = true;
      $userStore.answersAB = answersAB;
      $userStore.answersEN = answersEN;
      $userStore.finalScore = finalScore;
      $userStore.finalScoreTextAB = finalScoreTextAB;
      $userStore.finalScoreTextEN = finalScoreTextEN;
      $userStore.date = date;
    });

    socket.on("getQuestionsResponse", (params) => {
      const {questionsAB, questionsEN} = params;

      $questionABStore = questionsAB;
      $questionENStore = questionsEN;
      $appStore.isLoading = false;
    });
  });
</script>

<style>
  .app {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .content {
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
  }
</style>

<div class="app">
  <Header/>
  <div class="content" id="content">
    {#if $userStore.isLoggedIn && !$userStore.isFinished}
      <Questions/>
    {:else if $userStore.isLoggedIn && $userStore.isFinished}
      <Result/>
    {:else}
      <Signup/>
    {/if}
  </div>
</div>